import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ca874e5e = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _5f09fd04 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _7004a868 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _53bd7206 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _71ee8aec = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _266bfd9d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0d98656c = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _f35159f2 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _a8055f04 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _59c3105a = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _a2e7ea48 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _4fc6f2d2 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _2d7ccbef = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6240f4c8 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _2de38cb6 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _fa00be02 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _463668b6 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _3c2be4e8 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _edeb3832 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _1f4fd700 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _015b6824 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _b67e4d74 = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _7153d408 = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _221243ee = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _1cf0c70a = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _86b33aa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _f6aae852 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _3a3e6066 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _0ffb2e60 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _a6daa630 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _02d81a40 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _74e931d0 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _7bd21f30 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _ec68d770 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _23e49437 = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _0a21ccf8 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _39226f76 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _bd852228 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _d5157fe8 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _14a0bf3e = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _8c3937ae = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _385b39c7 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _35735adc = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _6ff05207 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _c07f934a = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _28ebf182 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _6d4e41a6 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _21e0878d = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _5a57ffbe = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _48f14a3d = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _bdd7abfe = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _b4adbaca = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _f73129c0 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _96ea0790 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _40f13d42 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _0cff53d3 = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _8e327d5e = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _8ff0324c = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _ab3fd42a = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _61204289 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _5e62d14c = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _d4931aea = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _63d528d8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _ca874e5e,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _5f09fd04,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _7004a868,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _53bd7206,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _71ee8aec,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _266bfd9d,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _0d98656c,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _f35159f2,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _a8055f04,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _59c3105a,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _a2e7ea48,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _4fc6f2d2,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _2d7ccbef,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _6240f4c8,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _2de38cb6,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _fa00be02,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _463668b6,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _3c2be4e8,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _edeb3832,
    name: "my-team"
  }, {
    path: "/news",
    component: _1f4fd700,
    name: "news"
  }, {
    path: "/notifications",
    component: _015b6824,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _b67e4d74,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _7153d408,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _221243ee,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _1cf0c70a,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _86b33aa2,
    name: "profile"
  }, {
    path: "/profiles",
    component: _f6aae852,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _3a3e6066,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _0ffb2e60,
    name: "settings"
  }, {
    path: "/stats",
    component: _a6daa630,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _02d81a40,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _74e931d0,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _7bd21f30,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _ec68d770,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _23e49437,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _0a21ccf8,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _39226f76,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _bd852228,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _d5157fe8,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _14a0bf3e,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _8c3937ae,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _385b39c7,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _35735adc,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _6ff05207,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _c07f934a,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _28ebf182,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _6d4e41a6,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _21e0878d,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _5a57ffbe,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _48f14a3d,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _bdd7abfe,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _b4adbaca,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _f73129c0,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _96ea0790,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _40f13d42,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _0cff53d3,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _8e327d5e,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _8ff0324c,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _ab3fd42a,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _61204289,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _5e62d14c,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _d4931aea,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _63d528d8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
